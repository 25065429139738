.cat-paragraph {
	margin-bottom: 20px;
}

.cat-media {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(560px, 1fr));
	grid-gap: 20px;
	justify-items: center;
}

.cat-media > * {
	max-width: 100%;
}