@import "./globals";

html {
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: $background-color;
	}

	/* Headings */
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: "Jost", sans-serif;
		color: $text-color-light;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	h1 {
		font-size: 3.25em;
	}
	h2 {
		font-size: 2em;
	}

	p {
		font-family: "Roboto Slab", serif;
		color: $text-color-dim;
		font-size: 1.5em;
		line-height: 1.75em;
	}

	#page {
		margin: 16px;
		margin-left: 17%;
		margin-right: 17%;
	}

	a {
		color: lightpink;
	}
}
