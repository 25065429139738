@import url(https://fonts.googleapis.com/css2?family=Jost&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@3/distr/fira_code.css);
@import url(https://fonts.googleapis.com/css2?family=Jost&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@3/distr/fira_code.css);
html body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#363c41}html h1,html h2,html h3,html h4,html h5,html h6{font-family:"Jost", sans-serif;color:#fff;margin-top:20px;margin-bottom:20px}html h1{font-size:3.25em}html h2{font-size:2em}html p{font-family:"Roboto Slab", serif;color:#ddd;font-size:1.5em;line-height:1.75em}html #page{margin:16px;margin-left:17%;margin-right:17%}html a{color:lightpink}

.bannerImage{max-width:100%;max-height:375px}

.cat-paragraph{margin-bottom:20px}.cat-media{display:grid;grid-template-columns:repeat(auto-fit, minmax(560px, 1fr));grid-gap:20px;justify-items:center}.cat-media>*{max-width:100%}

#navigation{position:-webkit-sticky;position:sticky;top:0;background-color:#23272a;display:grid;grid-template-columns:repeat(auto-fit, minmax(100px, 1fr));grid-auto-rows:minmax(-webkit-min-content, -webkit-max-content);grid-auto-rows:minmax(min-content, max-content)}#navigation .sub-menu{display:grid;grid-template-columns:repeat(auto-fit, minmax(100px, 1fr));grid-auto-rows:minmax(-webkit-min-content, -webkit-max-content);grid-auto-rows:minmax(min-content, max-content);grid-column:1 / span 3;grid-row-start:2}#navigation a{font-family:"Jost", sans-serif;color:#fff;text-decoration:none;font-size:1.5em;text-align:center;padding:10px}#navigation a.selected{background-color:rgba(255,255,255,0.2)}

